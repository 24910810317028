import { axios, Headers } from "../../../@core/auth/jwt/jwtService";
import {
  httpGetUsers,
  httpPutUsers,
  httpManageLogin,
  httpManageRegister,
  getPermissionsbyID,
  httpGetEnterprise,
  httpPostEnterprise,
  httpGetSubscriptions,
  httpGetDepartment,
  httpGetManagerPost,
  httpGetManagerGet,
  httpGetCategories,
  httpEnterpriseUserPost,
  httpEnterpriseUserGet,
  httpGetDepartmentByID,
  httpGetReviews,
  httpGetSME,
  httpGetReviewsBySMEID,
  httpUserRouting,
  httpPutPermissions,
  httpAddUser,
  httpPutEnterprise,
  httpChangeEntpStatus,
  httpChangeManagerStatus,
  httpChangeAdminUserStatus,
  httpChangeUserStatus,
  httpGetManagerByID,
  httpGetUSERSByManagerID,
  httpGetUSERSByDepID,
  httpSMERating,
  httpUpdateSME,
  httpIndividualUser,
  httpSMECategory,
  httpSMEAdminTitle,
  httpSMESMETitle,
  httpTITLEStatus,
  httpGetPackageEntp,
  httpRemarks,
  updateSMEReferences,
  updateSMETakeaways,
  httpUpdateAdminTags,
  httpGetSettings,
  httpLanguageSettings,
  httpRewards,
  httpLanguagePUTPOST,
  httpCharity,
  httpUserRewards,
  httpSubCategories,
  httpAddSubCat,
  httpUpdateSubCat,
  httpLangStatus,
  httpPassword,
  httpGetAllActiveCategories,
  httpTags,
  httpGetLibraries,
  httpAdminFlags,
  httpGetUserLibReviewsNotes,
  httpUpdateReward,
  httpDeleteReward,
  httpCreateReward,
  httpWebsiteLogo,
  httpChangeSMEStatus,
  httpGetSchedule,
  httpGetformdata,
  httpSubSubCategories,
  httpAddAddSubCat,
  httpUpdateUpSubCat,
  httpBulkUsers,
  httpGetSubSubBySMEID,
  httpGetAllTemplates,
  httpUpdateTemplate,
  httpUpdateUserTemplate,
  httpForgetPassMail,
  httpSMECount,
  httpManagerUsersTemp,
  httpDeleteCategories,
  httpDeleteSubCategories,
  httpDeleteSubSubCategories,
  httpDeleteLanguage,
  httpDeleteDraftTitle,
  httpCategoryTree,
  httpDeleteTemplate,
  httpDeleteAdminUser,
  httpDeleteEntpUser,
  httpDeleteAdminTags,
  httpDeleteEnterprise,
  httpDeleteManager,
  httpGetManagerLibraries,
  httpUpdateManagerTemplate,
  httpDeleteSubscription,
  httpDeleteSME,
  deleteSMETakeaways,
  httpVerifyToken,
  deleteSMEReferences,
  httpGetUserListInbox,
  httpGetChatByUserId,
  httpPostSendMessage,
  httpGetNotifications,
  httpUpdateNotifications,
  httpTutorialChangeBulkStatus,
  httpTutorialDuplicateIds,
  httpFCMSettings,
  httpCron,
  httpDashboardData,
} from "./ManageConstants";

let lastVerifiedTime = null;
const VERIFY_INTERVAL = 10 * 60 * 1000;

/**
 * Testing URL - Use these 2 for testing
 */

export let URL = "http://35.202.100.254/oe";

let cronURL = "http://35.202.100.254/oe/";

// Dont know this URL's purpose just let it be.
//Extra let it remain commented.
// let URL = "http://34.30.80.0/oe";

/**
 * Production URL to be used for when Production Server.
 */
// export let URL = "http://34.16.62.73/oe";

// let cronURL = "http://34.16.62.73/oe/";

// POST
const postManageHOF = (manageApi, data, id) => {
  // let URL = "http://192.168.1.10:3007"
  // let URL = localStorage.getItem('baseURL')

  // console.log('DATA::', data)
  if (id) {
    return axios.post(`${URL}${manageApi}?id=${id}`, data, Headers);
  }

  return axios.post(`${URL}${manageApi}`, data, Headers);
  // .then(response => {
  //       console.log('Response::', response);
  //       return response; // returning the response for further processing if needed
  //     })
};
const tokenManageHOF = (manageApi) => {
  let data = "";

  return axios.post(`${URL}${manageApi}`, data, Headers).then((response) => {
    console.log(JSON.stringify(response.data));
  });
};

// PUT
const putManageHOF = (manageApi, data, id) => {
  // let URL = "http://192.168.1.10:3007"
  // let URL = localStorage.getItem('baseURL')
  if (id) {
    return axios.put(`${URL}${manageApi}/${id}`, data, Headers);
  }

  return axios.put(`${URL}${manageApi}`, data, Headers);
};
// DELETE
const deleteManageHOF = (manageApi, data, id) => {
  // let URL = "http://192.168.1.10:3007"
  // let URL = "http://192.168.1.10:3007"
  // let URL = localStorage.getItem('baseURL')
  if (id) {
    return axios.post(`${URL}${manageApi}?id=${id}`, data, Headers);
  }

  return axios.delete(`${URL}${manageApi}`, data, Headers);
};

// GET
// Get Manage HOF
const getManageHOF = async (manageApi) => {
  // let resp = await VerifyToken(Headers.accesstoken)
  // console.log('VERIFY:===', resp)
  // console.log('VERIFY:===', Headers)

  // const currentTime = new Date().getTime();

  // Check if it's time to verify the token
  // if (!lastVerifiedTime || currentTime - lastVerifiedTime > VERIFY_INTERVAL) {
  //     await VerifyToken(data);
  //     lastVerifiedTime = currentTime;
  // }

  return axios.get(`${URL}${manageApi}`, Headers);
};

const downloadDashboard = async (from, to) => {
  try {
    const downloadURL = `${URL}/api/admin/download-group-by-user?from=${from}&to=${to}`;
    // Directly open the URL to let the browser handle the download
    // This is the download value over here.
    window.open(downloadURL, "_blank");
  } catch (error) {
    console.error("Error downloading the dashboard report:", error);
    throw error;
  }
};

const downloadReport = async (from, to) => {
  try {
    const downloadReportURL = `${URL}/api/admin/get-dynamic-progressive-report`;
    const response = await fetch(downloadReportURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ from, to}),
    });

    const result = await response.json();
    console.log("API Response:", result);

    if (result.success) {
      if (result.data) {
      const filePath = result.data;
      const fullUrl = `${URL}/${filePath}`;
      window.open(fullUrl, "_blank");
      } else {
        console.log("No file path returned in response data");
      }
    } else {
      throw new Error(result.message || "Report generation failed");
    }

  } catch (error) {
    console.error("Error downloading the download report:", error);
    throw error;
  }
}


// saad new functions.
const getSettingsEmail = async () => {
  try {
    const response = await fetch(`${URL}/api/admin/email-types`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// saad email template
const setMailControl = async (updatedData) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/turn-on-off-multi-option-mail-control`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

// saad email template
const setMailControlEnterprise = async (updatedData) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/turn-on-off-whole-enterprise-for-specific-template`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateSettingEmail = async (template_id, status) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/update-email-template-settings`,
      {
        method: "PUT", // Change to 'POST' if the API expects a POST request
        headers: {
          "Content-Type": "application/json",
          // Include authentication headers if required
        },
        body: JSON.stringify({
          template_id: template_id,
          status: status,
        }),
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating status:", error);
    toast.error("Error updating status: " + error.message);
  }
};

const updateEmailTemplate = async (template_id, html, subject) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/update-email-template-by-id`,
      {
        method: "PUT", // Change to 'POST' if the API expects a POST request
        headers: {
          "Content-Type": "application/json",
          // Include authentication headers if required
        },
        body: JSON.stringify({
          template_id: template_id,
          html: html,
          subject,
        }),
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating status:", error);
    toast.error("Error updating status: " + error.message);
  }
};
const getManagerEmailStatus = async (id) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/sync-user-emails/${id}/manager`
    );
    return response;
  } catch (error) {
    console.error("Error fetching status for managers:", error);
    throw error;
  }
};

// videoSettings
const getVideoSettings = async () => {
  try{
    const response = await fetch(
      `${URL}/api/admin/settings/ins_video_file`
    );

     const data = await response.json();
     return data;
  } catch(error) {
    console.error("Error in videoSettings", error);
      throw error;
  }
}

const postVideoSettings = async (formData) => {
  try{
    const response = await fetch(`${URL}/api/admin/settings-upload-instruction-video`, {
      method: 'POST',
      body: formData 
    })
    if(!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log("Upload success:", result);
    return response;
  } catch(error) {
    console.error("Error in videoSettings", error);
      throw error;
  }
}

const updateManagerEmailStatus = async (updatedData) => {
  try {
    const response = await fetch(`${URL}/api/admin/update-user-emails-status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // Include any required headers, such as authentication tokens
      },
      body: JSON.stringify(updatedData),
    });
    return response;
  } catch (error) {
    console.error("Error updating notifications:", error);
    throw error;
  }
};

const getUserEmailStatus = async (id) => {
  try {
    const response = await fetch(
      `${URL}/api/admin/sync-user-emails/${id}/user`
    );
    return response;
  } catch (error) {
    console.error("Error fetching status for users:", error);
    throw error;
  }
};

const updateUserEmailStatus = async (updatedData) => {
  try {
    const response = await fetch(`${URL}/api/admin/update-user-emails-status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // Include any required headers, such as authentication tokens
      },
      body: JSON.stringify(updatedData),
    });
    return response;
  } catch (error) {
    console.error("Error updating users:", error);
    throw error;
  }
};

const resetPassword = async (data) => {
  try {
    const response = await axios.post(
      `${URL}/api/admin/mb-reset-password`,
      data
    );
    return response.data; // assuming response has a `data` object
  } catch (error) {
    console.error("API Error in resetPassword:", error);
    throw error;
  }
};

const getManageHOFF = async (manageApi) => {
  const data = { apiCalled: "yes" };

  try {
    const response = await axios.post(
      `${cronURL}api/admin/run-schedule-crone`,
      data,
      Headers
    );

    return response;
  } catch (error) {
    console.error("Error in getManageHOF:", error);
    throw error;
  }
};

// Dynamic ROUTING
const getUserRoutingByID = (data) => getManageHOF(`${httpUserRouting}/${data}`);

// verify token
const VerifyToken = () => tokenManageHOF(httpVerifyToken);

// LOGIN - REGISTER
const postManageLogin = (data, id) => postManageHOF(httpManageLogin, data, id);
const postManageRegister = (data, id) =>
  postManageHOF(httpManageRegister, data, id);

// forget-pass email
const forgetPassMail = (data) => postManageHOF(httpForgetPassMail, data);

const dashboardData = (data) => postManageHOF(httpDashboardData, data);

// ADMIN USERS
const getUsers = () => getManageHOF(httpGetUsers);
const putUsers = (data, id) => putManageHOF(`${httpPutUsers}/${id}`, data);
const postUsers = (data) => postManageHOF(httpAddUser, data);
const postUserStatus = (data) => postManageHOF(httpChangeAdminUserStatus, data);

// PERMISSIONS
const getPermissionbyID = (data) => postManageHOF(getPermissionsbyID, data);
const postPermissions = (data) => postManageHOF(httpPutPermissions, data);

// Enterprise
const getEnterprise = () => getManageHOF(httpGetEnterprise);
const postEnterprise = (data) => postManageHOF(httpPostEnterprise, data);
const putEntpStatus = (data) => putManageHOF(httpChangeEntpStatus, data);
const putEnterprise = (data, id) =>
  putManageHOF(`${httpPutEnterprise}/${id}`, data);

// department
const getDepartment = () => getManageHOF(httpGetDepartment);
const postDepartment = (data) => postManageHOF(httpGetDepartment, data);
const putDepartment = (data, id) =>
  putManageHOF(`${httpGetDepartment}/${id}`, data);

// get dep by entp id
const getDepartmentBYID = (data) => postManageHOF(httpGetDepartmentByID, data);

// get dep by entp id
const getManagerBYID = (data) => postManageHOF(httpGetManagerByID, data);

// get USERS by - manager ID
const getUSERSByManagerID = (data) =>
  postManageHOF(httpGetUSERSByManagerID, data);
const updateManagerUsersTemp = (data) =>
  postManageHOF(httpManagerUsersTemp, data);

// get USERS by - DEP ID
const getUSERSByDepID = (data) => postManageHOF(httpGetUSERSByDepID, data);

// managers
const getManagers = () => getManageHOF(httpGetManagerGet);
const getManagersPaginated = (pageNo, pageSize, searchTerm = "") => {
  // Always include `search_word` in the query, even if it's empty
  const searchQuery = `&search_word=${encodeURIComponent(searchTerm)}`;
  return getManageHOF(
    `${httpGetManagerGet}?pageNo=${pageNo}&pageSize=${pageSize}${searchQuery}`
  );
};

const postManagers = (data) => postManageHOF(httpGetManagerPost, data);
const getCategories = () => getManageHOF(httpGetCategories);
const putManagerStatus = (data) => putManageHOF(httpChangeManagerStatus, data);
const updateManager = (data, id) =>
  putManageHOF(`${httpGetManagerPost}/${id}`, data);

// enterprise user
// saad paginated new user api
const getEnterpriseUserPaginated = (pageNo, pageSize, searchTerm = "") => {
  const searchQuery = `&search_word=${encodeURIComponent(searchTerm)}`;
  return getManageHOF(
    `${httpEnterpriseUserGet}?pageNo=${pageNo}&pageSize=${pageSize}${searchQuery}`
  );
};
const getEnterpriseUser = () => getManageHOF(httpEnterpriseUserGet);
const postEnterpriseUser = (data) =>
  postManageHOF(httpEnterpriseUserPost, data);
const putEntpUserStatus = (data) => putManageHOF(httpChangeUserStatus, data);
const updateEntpUser = (data, id) =>
  putManageHOF(`${httpEnterpriseUserPost}/${id}`, data);

const putEnterpriseUserTemplate = (data) =>
  putManageHOF(httpUpdateUserTemplate, data);
const putManagerTemplate = (data) =>
  putManageHOF(httpUpdateManagerTemplate, data);

// REVIEWS
const getReviews = () => getManageHOF(httpGetReviews);

// subscription Package Individual
const getSubscriptions = () => getManageHOF(httpGetSubscriptions);
const postPackage = (data) => postManageHOF(httpGetSubscriptions, data);
const putPackage = (data, id) =>
  putManageHOF(`${httpGetSubscriptions}/${id}`, data);

// package Enterprise
const getPackageEntp = () => getManageHOF(httpGetPackageEntp);

// SME
const getSME = () => getManageHOF(httpGetSME);
const postSME = (data) => postManageHOF(httpGetSME, data);
const getReviewsBySMEID = (data) => postManageHOF(httpGetReviewsBySMEID, data);
const updateSME = (data, id) => putManageHOF(`${httpUpdateSME}/${id}`, data);

const getSMERating = (data) => postManageHOF(httpSMERating, data);

const updateSMEStatus = (data) => putManageHOF(httpChangeSMEStatus, data);

// SME Category
const getSMECategory = () => getManageHOF(httpSMECategory);
const postSMECategory = (data) => postManageHOF(httpSMECategory, data);
const updateSMECategory = (data, id) =>
  putManageHOF(`${httpSMECategory}/${id}`, data);

// INDividual
// user
const getIndividualUser = () => getManageHOF(httpIndividualUser);

// SME Management
// ADMIN
const getSMEAdminTitle = (data) => postManageHOF(httpSMEAdminTitle, data);

// SME Management
// SME
const getSMESMETitle = (data) => postManageHOF(httpSMESMETitle, data);
const getSMECount = (id) => getManageHOF(`${httpSMECount}/${id}`);

const UpdateTitleStatus = (data, id) =>
  putManageHOF(`${httpTITLEStatus}/${id}/status`, data);
const addSMETitle = (data) => postManageHOF(httpTITLEStatus, data);
const updateSMETitle = (data, id) =>
  putManageHOF(`${httpTITLEStatus}/${id}`, data);

// saad
const tutorialDuplicationId = (data) =>
  postManageHOF(httpTutorialDuplicateIds, data);

const tutorialChangeBulkStatus = (data) =>
  postManageHOF(httpTutorialChangeBulkStatus, data);

const getRemarks = (id) => getManageHOF(`${httpRemarks}/title/${id}`);
const postRemarks = (data) => postManageHOF(httpRemarks, data);
const updateReferences = (data) => postManageHOF(updateSMEReferences, data);
const updateTakeaways = (data) => postManageHOF(updateSMETakeaways, data);
const UpdateAdminTags = (data) => postManageHOF(httpUpdateAdminTags, data);

const deleteTakeaways = (id) => deleteManageHOF(`${deleteSMETakeaways}/${id}`);
const deleteReferences = (id) =>
  deleteManageHOF(`${deleteSMEReferences}/${id}`);

// settings
const getSettings = () => getManageHOF(httpGetSettings);
const updateSettings = (data, id) =>
  putManageHOF(`${httpGetSettings}/${id}`, data);
const getSettingsByKey = (key) => getManageHOF(`${httpGetSettings}/${key}`);
const POSTWebsiteLogo = (data) => postManageHOF(httpWebsiteLogo, data);

// /////////////////// Push notifications ////////////////////////
// fcm settings
const getFCMSettings = () => getManageHOF(httpFCMSettings);
const postFCMSettings = (data) => postManageHOF(httpFCMSettings, data);
const updateFCMSettings = (data, id) =>
  putManageHOF(`${httpFCMSettings}/${id}`, data);
const deleteFCMSettings = (id) => deleteManageHOF(`${httpFCMSettings}/${id}`);

const getLanguages = () => getManageHOF(httpLanguageSettings);
const PUTLanguages = (data, id) =>
  putManageHOF(`${httpLanguagePUTPOST}/${id}`, data);
const POSTLanguages = (data) => postManageHOF(httpLanguagePUTPOST, data);
const LangStatus = (data) => putManageHOF(httpLangStatus, data);

const getRewards = () => getManageHOF(httpRewards);
// const postRewards = (data) => postManageHOF(httpCreateReward, data);
const postRewards = (data) => {
  return axios.post(`${URL}${httpCreateReward}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// const putReward = (data, id) => putManageHOF(`${httpUpdateReward}/${id}`, data);
const putReward = (data, id) => {
  return axios.post(`${URL}${httpUpdateReward}?id=${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteReward = (id) => deleteManageHOF(`${httpDeleteReward}/${id}`);

const deleteSubscription = (id) =>
  deleteManageHOF(`${httpDeleteSubscription}/${id}`);
const deleteSME = (id) => deleteManageHOF(`${httpDeleteSME}/${id}`);

const getCharity = () => getManageHOF(httpCharity);
const postCharity = (data, id) => postManageHOF(httpCharity, data, id);
const putCharity = (data, id) => putManageHOF(`${httpCharity}/${id}`, data);

// user libraries
const getUserRewards = () => getManageHOF(httpUserRewards);

const getSubCategories = (id) => getManageHOF(`${httpSubCategories}/${id}`);
const postSubCat = (data, id) => postManageHOF(httpAddSubCat, data, id);
const updateSubCat = (data, id) =>
  putManageHOF(`${httpUpdateSubCat}/${id}`, data);

const updatePassword = (data) => postManageHOF(httpPassword, data);

// sub sub cat
const getSubSubCategories = (id) =>
  getManageHOF(`${httpSubSubCategories}/${id}`);
const getAllSubSubCategories = (id) => getManageHOF(httpSubSubCategories);
const postSubSubCat = (data) => postManageHOF(httpAddAddSubCat, data);
const updateSubSubCat = (data, id) =>
  putManageHOF(`${httpUpdateUpSubCat}/${id}`, data);

const getSubSubBySMEID = (id) => getManageHOF(`${httpGetSubSubBySMEID}/${id}`);

const getAllActiveCategories = () => getManageHOF(httpGetAllActiveCategories);

const getTags = () => getManageHOF(httpTags);
const postTags = (data, id) => postManageHOF(httpTags, data, id);
const updateTags = (data, id) => putManageHOF(`${httpTags}/${id}`, data);
const updateTagsStatus = (data, id) =>
  putManageHOF(`${httpTags}/${id}/status`, data);

const getLibraries = (data, id) => postManageHOF(httpGetLibraries, data, id);
const getManagerLibraries = (data, id) =>
  postManageHOF(httpGetManagerLibraries, data, id);

const getFlags = (data, id) => getManageHOF(httpAdminFlags, data, id);

const getUserLibReviewsNotes = (data) =>
  getManageHOF(`${httpGetUserLibReviewsNotes}/${data}`);
const getManagerLibReviewsNotes = (data) =>
  getManageHOF(`${httpGetUserLibReviewsNotes}/${data}`);

const getAllActiveSchedule = (id) =>
  getManageHOF(`${httpGetSchedule}/${id}/user`);
const getAllActiveScheduleManager = (id) =>
  getManageHOF(`${httpGetSchedule}/${id}/manager`);
const postFormData = (data) => postManageHOF(httpGetformdata, data);

// bulk users in manager
const postBulkUsers = (data) => postManageHOF(httpBulkUsers, data);

// TEMPLATES

const getAllTemplates = () => getManageHOF(httpGetAllTemplates);
const postTemplates = (data) => postManageHOF(httpUpdateTemplate, data);
const getTemplatesByID = (id) => getManageHOF(`${httpGetAllTemplates}/${id}`);

const deleteCategories = (id) =>
  deleteManageHOF(`${httpDeleteCategories}/${id}`);
const deleteSubCategories = (id) =>
  deleteManageHOF(`${httpDeleteSubCategories}/${id}`);
const deleteSubSubCategories = (id) =>
  deleteManageHOF(`${httpDeleteSubSubCategories}/${id}`);
const deleteLanguage = (id) => deleteManageHOF(`${httpDeleteLanguage}/${id}`);
const deleteDraftTitle = (id) =>
  deleteManageHOF(`${httpDeleteDraftTitle}/${id}`);
const deleteTemplate = (id) => deleteManageHOF(`${httpDeleteTemplate}/${id}`);
const deleteAdminUser = (id) => deleteManageHOF(`${httpDeleteAdminUser}/${id}`);
const deleteEntpUser = (id) => deleteManageHOF(`${httpDeleteEntpUser}/${id}`);
const deleteAdminTags = (id) => deleteManageHOF(`${httpDeleteAdminTags}/${id}`);
const deleteEnterprise = (id) =>
  deleteManageHOF(`${httpDeleteEnterprise}/${id}`);
const deleteManager = (id) => deleteManageHOF(`${httpDeleteManager}/${id}`);

const getCategoryTree = (data) => postManageHOF(httpCategoryTree, data);

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////  CHAT  ////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

const postGetUserInboxList = (data) =>
  postManageHOF(httpGetUserListInbox, data);
const getUserChatById = (data) => postManageHOF(httpGetChatByUserId, data);
const postSendMessage = (data) => postManageHOF(httpPostSendMessage, data);

// ///////////////
// ///////////////// notifications
const getNotificationsAll = (data) => postManageHOF(httpGetNotifications, data);
const updateNotifications = (data) =>
  putManageHOF(httpUpdateNotifications, data);

//////////////////////////////
const getCron = () => getManageHOFF(httpCron);

export default {
  getCron,
  postFCMSettings,
  updateFCMSettings,
  deleteFCMSettings,
  getNotificationsAll,
  updateNotifications,

  postGetUserInboxList,
  getUserChatById,
  postSendMessage,
  VerifyToken,
  getSettingsByKey,

  deleteTakeaways,
  deleteReferences,
  getManagerLibraries,
  deleteSME,
  deleteSubscription,

  getCategoryTree,
  deleteTemplate,
  deleteAdminUser,
  deleteEntpUser,
  deleteAdminTags,
  deleteEnterprise,
  getAllActiveScheduleManager,
  deleteManager,
  deleteCategories,
  deleteSubCategories,
  deleteSubSubCategories,
  deleteLanguage,
  deleteDraftTitle,

  updateManagerUsersTemp,
  getSMECount,

  forgetPassMail,
  getAllTemplates,
  postTemplates,
  getTemplatesByID,

  getAllActiveSchedule,
  postFormData,

  getUserRoutingByID,
  getUsers,
  postUsers,
  putUsers,
  postUserStatus,
  getPermissionbyID,
  postPermissions,

  // enterprise
  getEnterprise,
  postEnterprise,
  putEnterprise,
  putEntpStatus,
  updateEntpUser,
  putEnterpriseUserTemplate,
  getManagerBYID,
  getUSERSByManagerID,
  getUSERSByDepID,
  getSubscriptions,
  postPackage,
  putPackage,
  getDepartment,
  postDepartment,
  getDepartmentBYID,
  putDepartment,
  postManagers,
  putManagerStatus,
  updateManager,
  getCategories,
  getEnterpriseUser,
  getEnterpriseUserPaginated,
  getManagers,
  getManagersPaginated,
  postEnterpriseUser,
  putEntpUserStatus,

  postManageLogin,
  postManageRegister,

  getReviews,
  getReviewsBySMEID,
  getSME,
  postSME,
  getSMERating,
  updateSME,
  getSMECategory,
  postSMECategory,
  updateSMECategory,

  getIndividualUser,

  getSMEAdminTitle,

  getSMESMETitle,
  updateSMEStatus,

  UpdateTitleStatus,
  addSMETitle,
  updateSMETitle,

  // entp package
  getPackageEntp,

  getRemarks,
  postRemarks,

  updateReferences,
  updateTakeaways,

  UpdateAdminTags,

  getSettings,
  updateSettings,
  POSTWebsiteLogo,
  getLanguages,

  getRewards,
  postRewards,
  putReward,
  deleteReward,

  POSTLanguages,
  PUTLanguages,
  LangStatus,
  getCharity,

  postCharity,
  putCharity,

  getUserRewards,

  getSubCategories,
  postSubCat,
  updateSubCat,

  getSubSubCategories,
  postSubSubCat,
  updateSubSubCat,
  getAllSubSubCategories,

  updatePassword,

  getAllActiveCategories,

  getTags,
  postTags,
  updateTags,
  updateTagsStatus,

  getLibraries,

  getFlags,

  getUserLibReviewsNotes,
  getManagerLibReviewsNotes,

  postBulkUsers,

  getSubSubBySMEID,

  putManagerTemplate,

  tutorialChangeBulkStatus,

  // saad
  tutorialDuplicationId,

  getFCMSettings,

  dashboardData,
  // saad
  getVideoSettings,
  postVideoSettings,
  postVideoSettings,
  downloadDashboard,
  downloadReport,
  getSettingsEmail,
  setMailControl,
  setMailControlEnterprise,
  updateSettingEmail,
  updateEmailTemplate,

  getManagerEmailStatus,
  updateManagerEmailStatus,
  getUserEmailStatus,
  updateUserEmailStatus,
  resetPassword,
};


 